<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Name</label>
          <b-form-input
              id="name"
              v-model="form.name.val"
              class="d-inline-block mr-1"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Genre</label>
          <v-select
              v-model="form.genre_id.val"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="getGenres"
              label="name_ar"
              :clearable="true"
              input-id="genre"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Artist</label>
          <v-select
              v-model="form.artist_id.val"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="getArtists"
              label="name"
              :clearable="true"
              input-id="artist"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="danger"
              class="mt-1"
              @click="filterVideos"
          >
            Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ref} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useVideoList from "@/views/apps/video/useVideoList";
import {avatarText} from "@core/utils/filter";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters('genre', ['getGenres']),
    ...mapGetters('artist', ['getArtists']),
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isLoading = ref()
    const {
      fetchVideos,
      tableColumns,
      perPage,
      pagination,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      videos,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useVideoList()

    const blankForm = {
      name: {
        type: 'like',
        val: null,
      },
      genre_id: {
        type: 'like',
        val: null,
      },
      artist_id: {
        type: 'like',
        val: null,
      },
    }
    const form = ref(blankForm)

    return {
      form,
      isLoading,
      // Sidebar
      fetchVideos,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      videos,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }

  },
  methods:{
    ...mapActions('genre', ['loadGenres']),
    ...mapActions('artist', ['loadArtists']),
    filterVideos() {
      this.$emit('filter', {page: 1, itemsPerPage: this.perPage}, this.form)
    }
  },
  created() {
    this.loadArtists()
    this.loadGenres()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
